<template>
  <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9 3.8103V12.5603C18.9 13.1405 18.6695 13.6969 18.2593 14.1071C17.849 14.5173 17.2926 14.7478 16.7125 14.7478H3.58746C3.0073 14.7478 2.4509 14.5173 2.04067 14.1071C1.63043 13.6969 1.39996 13.1405 1.39996 12.5603V3.8103" stroke="currentColor" stroke-width="1.47" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.9 3.8103C18.9 3.23014 18.6695 2.67374 18.2593 2.26351C17.849 1.85327 17.2926 1.6228 16.7125 1.6228H3.58746C3.0073 1.6228 2.4509 1.85327 2.04067 2.26351C1.63043 2.67374 1.39996 3.23014 1.39996 3.8103L8.99059 8.54989C9.33825 8.76718 9.73998 8.88239 10.15 8.88239C10.5599 8.88239 10.9617 8.76718 11.3093 8.54989L18.9 3.8103Z" stroke="currentColor" stroke-width="1.47" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEnvelopeSolid'
}
</script>
